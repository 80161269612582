<template>
  <div class="auth-page">
    <public-header :button="{url: '/login', text: $t('auth.sign_in')}"/>
    <div class="auth-page__content">
      <div class="container">
        <h2 class="auth-page__title">{{$t('auth.join_title')}}</h2>
        <div class="box mg-t-30 mg-b-30">
          <div class="title-bold center-text mg-b-20">{{$t('auth.benefit')}}</div>
          <div class="auth-page__line">{{$t('auth.benefit_opt1')}}</div>
          <div class="auth-page__line">{{$t('auth.benefit_opt2')}}</div>
          <div class="auth-page__line">{{$t('auth.benefit_opt3')}}</div>
        </div>
      </div>
      <div class="auth-steps">
        <div class="auth-steps__line"/>
        <div class="auth-step">
          <img src="/images/step1.png"/>
          <h4>{{$t('auth.step1')}}</h4>
          <p>{{$t('auth.step1_txt')}}</p>
        </div>
        <div class="auth-step">
          <img src="/images/step2.png"/>
          <h4>{{$t('auth.step2')}}</h4>
          <p>{{$t('auth.step2_txt')}}</p>
        </div>
        <div class="auth-step">
          <img src="/images/step3.png"/>
          <h4>{{$t('auth.step3')}}</h4>
          <p>{{$t('auth.step3_txt')}}</p>
        </div>
        <div class="auth-step">
          <img src="/images/step4.png"/>
          <h4>{{$t('auth.step4')}}</h4>
          <p>{{$t('auth.step4_txt')}}</p>
        </div>
        <div class="auth-step">
          <img src="/images/step5.png"/>
          <h4>{{$t('auth.step5')}}</h4>
          <p>{{$t('auth.step5_txt')}}</p>
        </div>
      </div>
      <div class="flex align-center justify-center mg-t-50">
        <router-link to="/register" class="btn btn--red w-200">{{$t('auth.join_now')}}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import publicHeader from '@/components/publicHeader/publicHeader'

export default {
  name: 'Entry',
  components: {
    publicHeader
  }
}
</script>

<style scoped lang="scss">
  @import "style";
</style>
