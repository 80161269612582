<template>
  <div class="header-p">
    <div class="container">
      <div class="flex align-center justify-between pd-t-10 pd-b-10">
        <router-link to="/"><img class="header-p__logo" src="/images/landing/logo-small.png"/></router-link>
        <div class="header-p__menu flex align-center justify-end mg-r-10" :class="{ opened: menuOpened }">
          <router-link :to="{path: '/', hash: '#welcome'}">{{$t('landing.menu1')}}</router-link>
          <router-link :to="{path: '/', hash: '#platform'}">{{$t('landing.menu2')}}</router-link>
          <router-link :to="{path: '/', hash: '#partner'}">{{$t('landing.menu3')}}</router-link>
          <router-link :to="{path: '/', hash: '#contact'}">{{$t('landing.menu4')}}</router-link>
          <div class="header-p__lang">
            <span :class="{selected: lang === 'zh-hk'}" @click="changeLang('zh-hk')">繁體中文</span> / <span :class="{selected: lang === 'en'}" @click="changeLang('en')">English</span>
          </div>
        </div>
        <div class="flex align-center">
          <router-link :to="button.url" class="btn btn--red w-150">{{button.text}}</router-link>
          <div class="header-p__menu-btn mg-l-20" tabindex="1" @click="toggleMenu(!menuOpened)" @focusout="toggleMenu(false)">
            <i class="fas fa-bars"/>
          </div>
        </div>
      </div>
    </div>
    <div class="header-p__red-line">
      <div class="container">
        {{$t('landing.improving_life')}}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { User } from '@/api/api'
export default {
  name: 'PublicHeader',
  props: [
    'button'
  ],
  data () {
    return {
      menuOpened: false,
      lang: 'en'
    }
  },
  computed: {
    ...mapGetters({
      isAuthed: 'auth/isAuthed',
      me: 'auth/me'
    })
  },
  watch: {
    '$route' (to, from) {
      if (to.fullPath.indexOf('/#') !== -1 || to.fullPath.indexOf('/info#') !== -1) {
        this.scrollToElement()
      }
    }
  },
  mounted () {
    this.lang = (this.isAuthed && this.me.language) || window.navigator.userLanguage || window.navigator.language

    this.$i18n.locale = this.lang
    window.lifeslocale = this.lang
    if (this.isAuthed && this.me.language !== this.lang) {
      this.saveLanguage()
    }

    if (this.$route.fullPath.indexOf('/#') !== -1 || this.$route.fullPath.indexOf('/info#') !== -1) {
      this.scrollToElement()
    }
  },
  methods: {
    scrollToElement () {
      const el = document.getElementById(this.$route.hash.substring(1))

      if (el) {
        el.scrollIntoView({
          behavior: 'smooth'
        })
      }
    },
    toggleMenu (val) {
      this.menuOpened = val
    },
    async saveLanguage () {
      try {
        await User.updateUser(this.me.id, {
          language: this.lang
        })
      } catch (err) {
        console.error('Failed to save selected language')
      }
    },
    changeLang (lang) {
      if (lang !== this.lang) {
        this.lang = lang
        window.lifeslocale = this.lang
        this.$i18n.locale = this.lang
        this.isAuthed && this.saveLanguage()
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "style";
</style>
